/* stylelint-disable */

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i");
@import "../node_modules/flag-icons/sass/flag-icons.scss";

@font-face {
    font-family: "Font Awesome 5 Solid";
    font-style: normal;
    font-weight: 900;
    src: url("/assets/fontawesome/webfonts/fa-solid-900.eot");
    src: url("/assets/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
        url("/assets/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"),
        url("/assets/fontawesome/webfonts/fa-solid-900.woff") format("woff"),
        url("/assets/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"),
        url("/assets/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
    font-family: "Font Awesome 5 Regular";
    font-style: normal;
    font-weight: 900;
    src: url("/assets/fontawesome/webfonts/fa-regular-400.eot");
    src: url("/assets/fontawesome/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
        url("/assets/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"),
        url("/assets/fontawesome/webfonts/fa-regular-400.woff") format("woff"),
        url("/assets/fontawesome/webfonts/fa-regular-400.ttf") format("truetype"),
        url("/assets/fontawesome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}
.fa,
.fas {
    font-family: "Font Awesome 5 Regular", serif;
    font-weight: 400;
}
@import "../src/styles/variables";
@import "../src/styles/typography";

::-moz-selection {
    background: #fab5d7; /* Gecko Browsers */
}

:root {
    --main-super-light-color: #fff0f7;
    --main-light-color: #f4479d;
    --main-color: rgb(226, 0, 122);
    --main-dark-color: #c90c6a;
    --white: #ffffff;
    --super-light-gray: #f9fafb;
    --light-gray: #eceff1;
    --gray: #d3d9dc;
    --dark-gray: #a1aaae;
    --light-gray-2: #74848b;
    --gray-2: #414a4e;
    --dark-gray-2: #1a1d1f;
    --black: #000000;
    --warning-orange: #fea351;
    --warning-orange-light: #fff6ee;
    --font-italic-12px: italic 12px Source Sans Pro;
    --font-normal-12px: normal 12px Source Sans Pro;
    --font-semibold-12px: 600 12px Source Sans Pro;
    --font-bold-12px: bold 12px Source Sans Pro;
    --font-italic-13px: italic 13px Source Sans Pro;
    --font-normal-13px: normal 13px Source Sans Pro;
    --font-semibold-13px: 600 13px Source Sans Pro;
    --font-bold-13px: bold 13px Source Sans Pro;
    --font-italic-14px: italic 14px Source Sans Pro;
    --font-normal-14px: normal 14px Source Sans Pro;
    --font-semibold-14px: 600 14px Source Sans Pro;
    --font-bold-14px: bold 14px Source Sans Pro;
    --font-normal-15px: normal 15px Source Sans Pro;
    --font-semibold-15px: 600 15px Source Sans Pro;
    --font-bold-15px: bold 15px Source Sans Pro;
    --font-normal-16px: normal 16px Source Sans Pro;
    --font-semibold-16px: 600 16px Source Sans Pro;
    --font-bold-16px: bold 16px Source Sans Pro;
    --font-normal-17px: normal 17px Source Sans Pro;
    --font-semibold-17px: 600 17px Source Sans Pro;
    --font-bold-17px: bold 17px Source Sans Pro;
    --font-normal-18px: normal 18px Source Sans Pro;
    --font-semibold-18px: 600 18px Source Sans Pro;
    --font-bold-18px: bold 18px Source Sans Pro;
    --font-normal-19px: normal 19px Source Sans Pro;
    --font-semibold-19px: 600 19px Source Sans Pro;
    --font-bold-19px: bold 19px Source Sans Pro;
    --font-normal-20px: normal 20px Source Sans Pro;
    --font-semibold-20px: 600 20px Source Sans Pro;
    --font-bold-20px: bold 20px Source Sans Pro;
    --font-normal-21px: normal 21px Source Sans Pro;
    --font-semibold-21px: 600 21px Source Sans Pro;
    --font-bold-21px: bold 21px Source Sans Pro;
    --font-normal-22px: normal 22px Source Sans Pro;
    --font-semibold-22px: 600 22px Source Sans Pro;
    --font-bold-22px: bold 22px Source Sans Pro;
    --font-normal-23px: normal 23px Source Sans Pro;
    --font-semibold-23px: 600 23px Source Sans Pro;
    --font-bold-23px: bold 23px Source Sans Pro;
    --bs-primary-rgb: 226, 0, 122;
}
* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}
*:focus {
    outline: 0;
    /* outline: 1px solid var(--main-light-color); */
}
html,
body {
    outline: 0;
    margin: 0;
    padding: 0;
}
li {
    list-style: none;
}
.top-bar {
    height: 5vh;
}
::selection {
    background: #fab5d7; /* WebKit/Blink Browsers */
}
/* ----------------- table ----------------- */
table {
    width: 90%;
    margin: 0 auto 30px auto;
    border-spacing: 0;
    font: var(--font-normal-16px);
    text-align: left;
}
td {
    border-bottom: 1px solid var(--gray);
    padding: 10px 0.5vw;
    color: var(--gray-2);

    a {
        color: var(--main-color);
        &:hover {
            color: var(--main-dark-color);
        }
    }

    &:nth-child(8) {
        max-width: 12vw;
    }
}
.filter td {
    border: none;
}
th {
    border-bottom: 1px solid var(--gray);
    font-weight: 600;
    color: var(--light-gray-2);
    padding: 0 0.5vw;
}
/* ----------------- tabs ----------------- */
ul.tabs {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    font: var(--font-normal-16px);
    border-bottom: 1px solid var(--gray);
}
ul.tabs > li {
    flex: 0 1;
    margin: 0 0 3px 0;
    padding: 0 10px;
    color: var(--light-gray-2);
    position: relative;
    -webkit-transition: color 0.2s cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: color 0.2s cubic-bezier(0, 0, 0.2, 1);
    transition: color 0.2s cubic-bezier(0, 0, 0.2, 1);
}
ul.tabs > li:hover,
ul.tabs > li.activeTab {
    color: var(--main-color);
}
ul.tabs > li::after,
ul.tabs > li.activeTab::after {
    content: "";
    width: 100%;
    height: 0;
    top: calc(100% + 2px);
    left: 0;
    position: absolute;
    background: var(--main-color);
    -webkit-transition: color 0.2s cubic-bezier(0, 0, 0.2, 1),
        height 0.2s cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: color 0.2s cubic-bezier(0, 0, 0.2, 1), height 0.2s cubic-bezier(0, 0, 0.2, 1);
    transition: color 0.2s cubic-bezier(0, 0, 0.2, 1), height 0.2s cubic-bezier(0, 0, 0.2, 1);
}
ul.tabs > li:hover::after,
ul.tabs > li.activeTab::after {
    content: "";
    height: 2px;
}
/* ----------------- form ----------------- */
.fa-asterisk {
    margin: 0 0 0 2px;
    color: $primary;
    font-size: 8px;
    vertical-align: top;
}

form {
    background: var(--light-gray);
    border: 1px solid var(--gray);
    padding: 1em;
    border-radius: 5px;
}

/* ----------------- select ----------------- */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 8px 26px 8px 16px;
    /* background: var(--white) url("assets/select_bg.png") no-repeat calc(100% - 10px) / 13px; */
}

ngb-datepicker {
    background-color: white;
    z-index: 100;
    font-size: 0.5rem;

    button.btn {
        margin: 0 8px;
        padding: 0 4px;
        background: none;
        border: 0;
        /* font: bold 14px "Source Sans Pro"; */
        color: var(--main-color);
        /* cursor: pointer; */
        box-shadow: none;
        transition: none;
        &:hover {
            box-shadow: none;
            transform: none;
        }
        &:focus {
            box-shadow: none;
            transform: none;
        }
    }

    .ngb-dp-header {
        background-color: var(--main-light-color);
    }

    select {
        background-color: var(--main-light-color);
        color: white;
    }
}

form label {
    font: $font-normal-16px;
    color: var(--black);
    white-space: nowrap;
}
form input[type="text"],
form input[type="phone"],
form input[type="email"],
form input[type="time"],
form input[type="password"],
form input[type="date"],
form label.form-file,
select,
form textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: 8px 16px;
    font: $font-normal-16px;
    color: var(--gray-2);
    border: 1px solid var(--gray);
    border-radius: 4px;
    position: relative;
}
form input[type="text"]:disabled,
form input[type="email"]:disabled,
form input[type="time"]:disabled,
form input[type="password"]:disabled,
form input[type="date"]:disabled,
form label.form-file:disabled,
select:disabled,
form textarea:disabled {
    background-color: var(--gray);
}
form input[type="text"]::placeholder,
form input[type="email"]::placeholder,
form input[type="time"]::placeholder,
form input[type="password"]::placeholder,
form input[type="date"]::placeholder,
form label.form-file::placeholder,
select::placeholder,
form textarea::placeholder {
    color: var(--dark-gray);
}
form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="time"]:focus,
form input[type="password"]:focus,
form input[type="date"]:focus,
form label.form-file:hover,
select:focus,
form textarea:focus {
    border: 1px solid var(--main-light-color);
}
form button[type="submit"]:disabled {
    pointer-events: none;
    color: var(--light-gray-2);
    background-color: var(--gray);
    border-color: var(--light-gray-2);
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset;
    box-shadow: 0 0 0 30px var(--white) inset;
    -webkit-text-fill-color: var(--dark-gray) !important;
    border: 1px solid var(--main-color) !important;
}
form input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
form label.form-file {
    width: 73%;
    display: inline-block;
    background: var(--main-color);
    border: 1px solid transparent;
    border-radius: 0;
    font: var(--font-bold-14px);
    color: var(--white);
}
form label.form-file:hover {
    background: var(--white);
    color: var(--main-color);
    border: 1px solid var(--main-color);
}
form label.form-file svg {
    margin: 0 6px -2px 0;
}
/* ----------------- error ----------------- */
form .errorMessage {
    width: fit-content;
    display: block;
    position: relative;
    border-radius: 6px;
    color: var(--main-color);
    font: var(--font-normal-13px);
    background-color: var(--main-super-light-color);
    white-space: nowrap;
}
/* ----------------- radio ----------------- */
form input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 5px 0 -4px 0;
    padding: 7px;
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 50%;
}
form input[type="radio"]:hover {
    border: 1px solid var(--main-color);
}
form input[type="radio"]:checked {
    border: 1px solid var(--main-color);
}

form input[type="radio"]:checked::after {
    content: "";
    display: block;
    height: 4px;
    width: 4px;
    margin: -2px -3px -3px -2px;
    background-color: var(--main-color);
    color: var(--main-color);
    border-radius: 50%;
}

button {
    text-transform: uppercase;
}
button.btn {
    margin: 0 17px;
    padding: 6px 16px;
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-radius: 2px;
    font: $font-bold-14px;
    color: var(--main-color);
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1),
        box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
        box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
        box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1);

    &:hover {
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    }

    &:focus {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        transform: translateY(+0px);
    }
}
button.toggle:focus {
    background-color: var(--main-color);
    color: var(--white);
}
button.add span {
    margin: -7px 10px -8px -16px;
    padding: 6px 8px;
    background-color: var(--main-color);
    color: var(--white);
    display: inline-block;
}
button.add:hover span {
    background-color: var(--main-light-color);
}
button.add span svg {
    vertical-align: text-bottom !important;
}
button.small {
    margin: 0 5px;
    padding: 6px 13px;
}
button.inverse {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    background-color: var(--main-color);
    color: var(--white);
}
button.inverse:hover {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    background-color: var(--white);
    color: var(--main-color);
    transform: none;
}
button.inverse:disabled {
    pointer-events: none;
    color: var(--light-gray-2);
    background-color: var(--gray);
    border-color: var(--light-gray-2);
    &:hover {
        box-shadow: none;
        transform: none;
    }
}
button.btn.disabled {
    color: var(--light-gray-2);
}
button.btn.add.disabled {
    color: var(--light-gray-2);
}
button.add.disabled span {
    background-color: var(--light-gray-2);
}

.disabled .btn {
    background-color: var(--gray);
    border: 1px solid var(--light-gray-2);
}
/* Filter button  */
button.filter {
    float: right;
    margin: 0 17px 30px 17px;
    padding: 6px 16px;
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-radius: 16px;
    font: $font-bold-14px;
    color: var(--main-color);
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1),
        box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
        box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
        box-shadow 0.2s cubic-bezier(0, 0, 0.2, 1);
}
button.filter:hover {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    transform: translateY(-3px);
}
button.filter svg {
    margin: 0 0 0 5px;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
}
button.filter svg:hover {
    transform: scale(1.5);
}
button.filter.activeFilter {
    background: var(--main-color);
    color: var(--white);
}

.custom-control-input {
    margin: auto;
    padding: 0 0 0 26px;
}

.custom-switch {
    padding: 0 !important;
}
.custom-control-label {
    margin-left: 15px;
    &::before {
        left: 0 !important;
    }
    &::after {
        left: 2px !important;
    }
}

button.--modern {
    padding: 8px;

    border: none;
    border-radius: $modern-border-radius;

    background-color: $modern-button-color;
    color: white;

    font: $font-semibold-16px;

    text-transform: none;

    &:hover,
    &:active {
        cursor: pointer;
    }

    &:hover {
        background-color: darken($modern-button-color, 8%);
    }

    &:active {
        background-color: darken($modern-button-color, 16%);
    }

    &:disabled {
        background-color: darkgray;
    }
}

.buttonGroup {
    > button {
        border-radius: 0;
    }

    > button:first-child {
        border-top-left-radius: $modern-border-radius;
        border-bottom-left-radius: $modern-border-radius;
    }

    > button:last-child {
        border-top-right-radius: $modern-border-radius;
        border-bottom-right-radius: $modern-border-radius;
    }
}

/* ----------------- Alerts ----------------- */
.successMessage {
    position: fixed;
    width: 90%;
    border-radius: 6px;
    background-color: #edfbf6;
    color: #32d296;
    padding: 6px 10px;
    margin: 10px 5% 0 5%;
    text-align: center;
    font: $font-normal-18px;
    z-index: 1000;
    animation-name: showMessage;
    animation-duration: 1s;
    animation-timing-function: ease;
}
.successMessage p {
    margin: 0;
    padding: 0;
}

.errorMessage {
    position: fixed;
    width: 90%;
    border-radius: 6px;
    background-color: #fef4f6;
    color: #f0506e;
    padding: 6px 10px;
    margin: 10px 5% 0 5%;
    text-align: center;
    font: $font-normal-18px;
    z-index: 1000;
    animation-name: showMessage;
    animation-duration: 1s;
    animation-timing-function: ease;
}
.errorMessage p {
    margin: 0;
    padding: 0;
}

.splashscreen {
    &__spinner-container {
        height: 70px;
        width: 70px;
        position: relative;
        display: block;
        margin: 45vh auto 0 auto;
        text-align: center;
        svg {
            color: $primary;
        }
    }

    &__loading-text {
        font: $font-normal-18px;
        color: var(--main-light-color);
        position: relative;
        display: block;
        margin: 10px auto 0 auto;
        text-align: center;
    }
}

/* Autofill CSS */
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid orange;
    -webkit-text-fill-color: #000000;
    -webkit-box-shadow: 0 0 0 1000px #f7d7e7 inset;
    box-shadow: 0 0 0 1000px #f7d7e7 inset;
    transition: background-color 5000s ease-in-out 5000s;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: #000000 !important;
}

/* Standards for pages */
.content-margin {
    margin: 2rem;
}

.standard-container {
    display: flex;
    flex-wrap: wrap;
    margin: 3vh 7% 10vh 7%;
    padding: 45px 50px;
    background-color: var(--white);
    border-radius: 5px;
    border: 1px solid var(--gray);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.infoContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 3vh 7% 10vh 7%;
    padding: 45px 50px;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $gray-500;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    justify-content: space-between;
    align-items: center;
    font: $font-normal-18px;

    .btn {
        height: fit-content;
    }
    .icon {
        padding-right: 10px;
    }

    &.warning {
        background-color: $yellow;
        border: 1px solid $orange;
    }
    &.danger {
        background-color: $quaternary;
        border: 1px solid $primary;
    }
    &.success {
        background-color: #edfbf6;
        border: 1px solid #32d296;
    }
}

/*Pagination */
.pagination {
    width: fit-content;
    margin: 20px auto;
    .clickable {
        margin: auto 5px;
        cursor: pointer;
        &.invisible {
            pointer-events: none;
            opacity: 0;
        }
    }
}

/*Fix for bootstrap checkboxes */
input[type="checkbox"]::after {
    box-sizing: content-box;
}
