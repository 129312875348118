/* Your variable overrides */
$white: #ffff;
$yellow: #fff6ee;
$orange: #fea351;
$green: #15ac38;
$red: #dd0008;
$gray: #a1aaae;
$black: #000000;
$gray-900: #1a1d1f;
$gray-800: #414a4e;
$gray-700: #74848b;
$gray-600: #a1aaae;
$gray-500: #d3d9dc;
$gray-400: #eceff1;
$gray-300: #f9fafb;
/* $light-gray: $gray-500; */
$light-gray: $gray-400;
$pink: #f4479d;

$primary: #e2007a;
$secondary: #c90c6a;
$tertiary: #f4479d;
$quaternary: #fff0f7;

$cyan: #4169e1;
$body-bg: #eceff1;
$body-error-bg: #fef4f6;
$body-color: #74848b;
$link-color: #f4479d;
$link-hover-color: #c90c6a;
$button-hover-color: $quaternary;
$border-color: #a1aaae;
$table-color: #74848b;
$table-head-bg: #74848b;
$table-head-color: #74848b;
/* $custom-radio-indicator-icon-checked: #E2007C; */
$input-disabled-bg: #d3d9dc;
$btn-link-disabled-color: #d3d9dc;
$nav-link-disabled-color: #d3d9dc;
$jumbotron-bg: $white;
$custom-select-disabled-bg: #d3d9dc;
$custom-select-disabled-color: #d3d9dc;
$alert-border-level: -4;
$alert-bg-level: -9;
$input-border-color: #d3d9dc;
$toast-border-color: #d3d9dc;

/* Fonts */
$font-family-sans-serif: "Source Sans Pro";
$font-family-monospace: "Source Sans Pro";
$font-family-base: "Source Sans Pro";
$font-bold-14px: bold 14px "Source Sans Pro";
$font-normal-16px: normal 16px "Source Sans Pro";
$font-semibold-16px: 600 16px "Source Sans Pro";
$font-semibold-16px: 600 16px "Source Sans Pro";
$font-bold-16px: bold 16px "Source Sans Pro";
$font-normal-18px: normal 18px "Source Sans Pro";
$font-bold-18px: bold 18px "Source Sans Pro";
$font-semibold-20px: 600 20px "Source Sans Pro";
$font-semibold-22px: 600 22px "Source Sans Pro";
$font-semibold-20px: 600 20px "Source Sans Pro";
$font-semibold-22px: 600 22px "Source Sans Pro";
$font-bold-22px: bold 22px "Source Sans Pro";
$font-normal-24px: normal 24px "Source Sans Pro";
$font-bold-40px: bold 40px "Source Sans Pro";
$font-bold-40px: bold 40px "Source Sans Pro";

/* Alias */
$main-color: $primary;
$main-dark-color: $secondary;
$main-light-color: $tertiary;

$max-width-content-page: 2400px;

$bs-primary-rgb: $primary;

.button-danger {
    background-color: $red;
    color: $white;
    border: 1px $white solid;
}

$sidebar-collapsed-width: 60px;
$sidebar-width: 300px;
$modern-border-radius: 12px;
$modern-button-color: #d50672;
