@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i");
@import "../styles/variables";
@font-face {
    font-family: "Font Awesome 5 Solid";
    font-style: normal;
    font-weight: 900;
    src: url("/assets/fontawesome/webfonts/fa-solid-900.eot");
    src: url("/assets/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
        url("/assets/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"),
        url("/assets/fontawesome/webfonts/fa-solid-900.woff") format("woff"),
        url("/assets/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"),
        url("/assets/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
    font-family: "Font Awesome 5 Regular";
    font-style: normal;
    font-weight: 900;
    src: url("/assets/fontawesome/webfonts/fa-regular-400.eot");
    src: url("/assets/fontawesome/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
        url("/assets/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"),
        url("/assets/fontawesome/webfonts/fa-regular-400.woff") format("woff"),
        url("/assets/fontawesome/webfonts/fa-regular-400.ttf") format("truetype"),
        url("/assets/fontawesome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}

.fa,
.fas {
    font-family: "Font Awesome 5 Regular", serif;
    font-weight: 400;
}

/* ----------------- Typography Variables ----------------- */

/* ~~ 14px */
$font-italic-small: italic 0.875rem Source Sans Pro;
$font-regular-small: normal 0.875rem Source Sans Pro;
$font-semibold-small: 600 0.875rem Source Sans Pro;
$font-bold-small: bold 0.875rem Source Sans Pro;

/* ~~ 16px */
$font-regular-normal: normal 1rem Source Sans Pro;
$font-semibold-normal: 600 1rem Source Sans Pro;
$font-bold-normal: bold 1rem Source Sans Pro;

/* ~~ 18px */
$font-regular-large: normal 1.125rem Source Sans Pro;
$font-semibold-large: 600 1.125rem Source Sans Pro;
$font-bold-large: bold 1.125rem Source Sans Pro;

/* Headers */
$title-h1: normal 1.5rem Source Sans Pro;
$title-h2: normal 1.375rem Source Sans Pro;
$title-h3: normal 1.25rem Source Sans Pro;
$title-h4: normal 1.125rem Source Sans Pro;

/* body */
$body-text: $font-regular-normal;

/* special characters / fontawesome */
$special-asterix: normal 8px Source Sans Pro;

/* ----------------- default text ----------------- */

html,
body {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    color: $gray-700;
}

a {
    text-decoration: none;
}

/* ----------------- headers ----------------- */

h1 {
    color: $primary;
    font: $title-h1;
}

h2 {
    color: $primary;
    font: $title-h2;
}

h3 {
    color: $primary;
    font: $title-h3;
}

h4 {
    color: $primary;
    font: $title-h4;
}
